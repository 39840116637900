const league = [
  {
    path: '/league',
    name: 'league',
    component: () => import('@/views/pages/League.vue'),
    children: [
      {
        path: '',
        name: 'league-home',
        component: () => import('@/views/components/league/HomeTab.vue'),
        meta: {
          layout: 'content',
          resource: 'Public',
        },
      },
      {
        path: 'bans',
        name: 'league-bans',
        component: () => import('@/views/components/league/BanTab.vue'),
        meta: {
          layout: 'content',
          resource: 'Public',
        },
      },
      {
        path: 'rules',
        name: 'league-rules',
        component: () => import('@/views/components/league/InformationTab.vue'),
        meta: {
          layout: 'content',
          resource: 'Public',
        },
      },
    ],
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
]

export default league
