<template>
  <v-snackbar
    v-model="showSnackbar"
    color="success"
    bottom
    right
    :timeout="3000"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="showSnackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      showSnackbar: false,
      message: 'Login successful!',
    }
  },
  methods: {
    show(message) {
      this.message = message
      this.showSnackbar = true
    },
  },
}
</script>
