<template>
  <v-dialog
    v-model="value"
    persistent
    max-width="600px"
  >
    <v-card v-if="loading">
      <v-card-title>
        <span class="headline">Report player form</span>
      </v-card-title>

      <v-card-text>
        <v-container>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" sm="12" md="12" align="center" justify="center">
                <v-progress-circular
                    indeterminate
                    color="primary">
                </v-progress-circular>
              </v-col>
            </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="headline">Report player form</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="form"
            class="report-data"
            v-model="valid"
          >
            <v-row class="pa-0 ma-0">
              <v-col cols="1" sm="4" md="4" justify="center">Recent matches</v-col>
              <v-col cols="1" sm="8" md="8" justify="center">Players</v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" sm="4" md="4" align="center" justify="center" v-if="matches === undefined">
                <v-progress-circular
                    indeterminate
                    color="primary">
                </v-progress-circular>
              </v-col>
              <v-col cols="12" sm="4" md="4" justify="center" v-else-if="matches.length <= 0">
                You haven't played any matches in the last week!
              </v-col>
              <v-col cols="12" sm="4" md="4" justify="center" v-else>
                <v-btn block class="mt-2" :class="selectedMatch == match ? 'active-button' : ''" v-for="(match, index) in matches" :key="match.id + index" @click="selectMatch(match)">{{ unixToFormattedTimeMonthNumeric(match.started_at) }}</v-btn>
              </v-col>
              <v-col cols="12" sm="8" md="8" justify="center" v-if="selectedMatch == null">
                Before selecting a player you have to select the match where the offense was made.
              </v-col>
              <v-col cols="12" sm="4" md="4" justify="center" v-if="selectedMatch != null">
                <v-btn 
                  block 
                  class="mt-2" 
                  :class="selectedPlayer == player ? 'active-button' : ''" 
                  v-for="(player, index) in selectedMatch.team1" 
                  :key="player.nickname + index" 
                  :disabled="player.id == $store.getters['user/GetLocalUserData'].faceit_id"
                  @click="selectPlayer(player)"
                >
                    {{ player.nickname }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="4" md="4" justify="center" v-if="selectedMatch != null">
                <v-btn 
                  block 
                  class="mt-2" 
                  :class="selectedPlayer == player ? 'active-button' : ''" 
                  v-for="(player, index) in selectedMatch.team2" 
                  :key="player.nickname + index" 
                  :disabled="player.id == $store.getters['user/GetLocalUserData'].faceit_id"
                  @click="selectPlayer(player)"
                >
                    {{ player.nickname }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" sm="12" md="12">
                <v-select
                  :items="['Griefing', 'Throwing', 'Smurfing', 'Hacking', 'Leaver', 'Not using voice', 'Leaderboard Abuse']"
                  label="Griefing"
                  v-model="offense"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  label="Describe the offenses - be as detailed as possible!"
                  rows="2"
                  outlined
                  v-model="description"
                  :rules="descriptionRules"
                  counter="250"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" >
                <v-text-field
                  outlined
                  v-model="evidence"
                  :label="'Evidence (' + enteredEvidence.length + '/' + maxEvidence + ')'"
                  :disabled="enteredEvidence.length >= maxEvidence"
                  hint="Link to the clip, image or other proof"
                >
                  <template v-slot:append-outer>
                    <v-fade-transition leave-absolute >
                      <v-btn
                        color="dark"
                        style="margin-top: -10px"
                        @click="addEvidenceToList()"
                        :disabled="!isValidUrl(evidence) || enteredEvidence.length >= maxEvidence || evidence.length <= 0"
                      >
                        Add to evidence
                      </v-btn>
                    </v-fade-transition>
                  </template>
                </v-text-field>
                <v-list
                  two-line
                  dense
                >
                  <v-list-item
                    v-for="(value, index) in enteredEvidence"
                    :key="value + index"
                    dense
                    class="bg-social-list mb-2"
                  >
                    <v-btn
                      small
                      color="error"
                      class="mr-2"
                      @click="enteredEvidence.splice(index, 1)"
                    >
                      Delete
                    </v-btn>

                    <v-list-item-content>
                      <v-list-item-title>{{ value }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="$emit('input', false)"
        >
          Cancel
        </v-btn>
        <v-btn
          color="success"
          :disabled="!valid || selectedMatch == null || selectedPlayer == null"
          @click="makeReport()"
        >
          Send report
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isValidUrl } from '@core/utils/'
import { unixToFormattedTimeMonthNumeric } from '@core/utils/'
import axiosClient from '@/plugins/axios'

export default {
  props: ['value'],
  data() {
    return {
      selectedMatch: null,
      selectedPlayer: null,
      offense: 'Griefing',
      description: '',
      evidence: '',
      maxEvidence: 5,
      enteredEvidence: [],
      descriptionRules: [
        v => !!v || 'Description is required',
        v => v.length <= 250 || 'Description must be less than 250 characters',
      ],
      valid: false,
      loading: false,
    }
  },
  watch: {
    value(val) {
      if (!val) return
      const isLoggedIn = this.$store.getters['user/IsLoggedIn']
      if (!isLoggedIn) return
      this.$store.dispatch('user/loadPlayerRecentMatches', this.$store.getters['user/GetLocalUserData'].faceit_id)
    },
  },
  computed: {
    matches() {
      return this.$store.getters['user/GetLocalUserRecentMatches']
    },
  },
  methods: {
    selectMatch(match) {
      if (this.selectedMatch == match) this.selectedMatch = null
      else this.selectedMatch = match
      this.selectedPlayer = null
    },
    selectPlayer(player) {
      if (this.selectedPlayer == player) this.selectedPlayer = null
      else this.selectedPlayer = player
    },
    addEvidenceToList() {
      this.enteredEvidence.push(this.evidence)
      this.evidence = ''
    },
    makeReport() {
      this.loading = true
      axiosClient({
        url: this.$API + '/report/',
        method: 'POST',
        data: {
          match: this.selectedMatch.id,
          targetPlayer: this.selectedPlayer.id,
          offense: this.offense,
          description: this.description,
          evidence: this.enteredEvidence,
        },
      })
        .then(result => {
          this.loading = false
          this.selectedMatch = null
          this.selectedPlayer = null
          this.offense = 'Griefing'
          this.description = ''
          this.enteredEvidence = []
          this.$store.dispatch('report/loadReporterReports')
          if (this.$store.getters['user/IsAuthorized']('manage_reports')) {
            this.$store.dispatch('report/reloadUnassignedReportsPages')
          }
          this.$root.vtoast.show('The report was made successfully!')
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          this.$root.vtoast.show('An error occurred while making the report!')
        })
    },
    isValidUrl,
    unixToFormattedTimeMonthNumeric,
  },
}
</script>