import axiosClient from '@/plugins/axios'

var url = undefined
export default {
  namespaced: true,
  state: {
    hubs: [{ name: 'Division 1', loading: true }],
    bans: [],
  },
  mutations: {
    SetHubs: async (state, hubs) => {
      state.hubs = hubs.map(x => {
        x.key = 0
        return x
      })

      for (var hub of state.hubs) {
        try {
          let response = await axiosClient.get(url + `/league/` + hub.id + `/leaderboard/`)
          if (response.data.errorMessage !== undefined) throw Exception()
          hub.leaderboard = response.data
        } catch (_) {
          hub.leaderboardError = hub.name + ' leaderboard failed to load!'
        }
        hub.key += 1
      }

      for (var hub of state.hubs) {
        try {
          let response = await axiosClient.get(url + `/league/` + hub.id + `/matches/live`)
          if (response.data.errorMessage !== undefined) throw new Error(hub.name + ' failed to load!')
          if (response.data.length == 0) throw new Error(hub.name + ' has no live matches!')
          hub.live_matches = response.data
        } catch (e) {
          hub.liveMatchesError = e.message
        }
        hub.key += 1
      }
    },
    SetBans: (state, bans) => {
      state.bans = bans
    },
  },
  actions: {
    async loadHubs(state) {
      if (url === undefined) url = this._vm.$API
      let response = await axiosClient.get(url + `/league/`)
      state.commit('SetHubs', response.data)
    },
    async loadBans(state) {
      if (url === undefined) url = this._vm.$API
      let response = await axiosClient.get(url + `/league/bans/`)
      state.commit('SetBans', response.data)
    },
  },
  getters: {
    GetHubs: state => state.hubs,
    GetBans: state => state.bans,
  },
}
