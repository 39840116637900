const profile = [
  {
    path: '/profile',
    name: 'my-profile',
    component: () => import('@/views/pages/Profile.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/profile/edit',
    name: 'my-profile-edit',
    component: () => import('@/views/pages/EditProfile.vue'),
    children: [
      {
        path: '',
        name: 'profile-edit-home',
        component: () => import('@/views/components/profile/edit/AccountSettingsInfo.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'accounts',
        name: 'profile-edit-accounts',
        component: () => import('@/views/components/profile/edit/AccountSettingsLinkedAccounts.vue'),
        meta: {
          layout: 'content',
        },
      },
    ],
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/profile/:id',
    name: 'other-profile',
    component: () => import('@/views/pages/Profile.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default profile
