import axiosClient from '@/plugins/axios'
import Vue from 'vue'

var url = undefined
export default {
  namespaced: true,
  state: {
    tournaments: [],
  },
  mutations: {
    ResetTournaments: state => {
      state.tournaments = []
    },
    SetTournaments: async (state, { page, tournaments }) => {
      for (var tournament of tournaments.data) {
        try {
          let response = await axiosClient.get(url + `/tournaments/` + tournament.id + `/matches`)
          tournament.past_matches = response.data.past_matches
          tournament.upcoming_matches = response.data.upcoming_matches
          tournament.ongoing_matches = response.data.ongoing_matches
        } catch (e) {
          tournament.past_matches = [];
          tournament.upcoming_matches = [];
          tournament.ongoing_matches = [];
        }
      }
      Vue.set(state.tournaments, page, tournaments)
    },
  },
  actions: {
    async loadTournaments(state, { page }) {
      if (url === undefined) url = this._vm.$API
      let last = null
      if (page > 1) last = state.state.tournaments[page - 2].last
      let params = new URLSearchParams()
      if (last) {
        params.append('id', last.id)
        params.append('start_time', last.start_time)
      }
      let response = await axiosClient.get(url + `/tournaments/`, { params })
      state.commit('SetTournaments', { page: page - 1, tournaments: response.data })
    },
    async reloadPages(state) {
      let maxPage = state.state.tournaments.length
      for (let i = 1; i <= maxPage; i++) {
        state.dispatch('tournament/loadTournaments', { page: i }, { root: true })
      }
    },
  },
  getters: {
    HasPageLoaded: state => page => state.tournaments.length >= page,
    GetAllPages: state => state.tournaments,
    GetFirstTournament: state =>
      state.tournaments === undefined || state.tournaments.at(0) == undefined
        ? undefined
        : state.tournaments.at(0).data.at(0),
    GetTournaments: state => page => state.tournaments.at(page - 1),
    GetCount: state => () => {
      let lastTournament = state.tournaments.find(xy => xy.last === undefined)
      if (lastTournament === undefined) return 10000000
      return state.tournaments.indexOf(lastTournament) + 1
    },
  },
}
