<template>
  <v-btn
    v-if="item.outsideLink"
    :color="isActive ? 'primary' : null"
    depressed
    rounded
    :href="item.to.toString()"
    target="_blank"
    large
    class="horizontal-nav-menu-header-link px-6 floatr"
  >
    <v-icon
      dark
      left
      :size="item.icon ? 22 : 15"
    >
      {{ item.icon || alternateIcon }}
    </v-icon>
    {{ t(item.title) }}
  </v-btn>
  <v-badge
    v-else-if="item.to === 'draft'"
    color="secondary"
    :content="$store.getters['statistics/GetDraftedPlayerCount']"
    overlap
  >
    <v-btn
      :color="isActive ? 'primary' : null"
      depressed
      rounded
      :to="{ name: item.to }"
      large
      exact
      class="horizontal-nav-menu-header-link px-6"
    >
      <v-icon
        dark
        left
        :size="item.icon ? 22 : 15"
      >
        {{ item.icon || alternateIcon }}
      </v-icon>
      {{ t(item.title) }}
    </v-btn>
  </v-badge>
  <v-btn
    v-else
    :color="isActive ? 'primary' : null"
    depressed
    rounded
    :to="{ name: item.to }"
    large
    exact
    class="horizontal-nav-menu-header-link px-6"
  >
    <v-icon
      dark
      left
      :size="item.icon ? 22 : 15"
    >
      {{ item.icon || alternateIcon }}
    </v-icon>
    {{ t(item.title) }}
  </v-btn>
</template>

<script>
import useHorizontalNavMenuHeaderLink from '@core/layouts/composable/horizontal-nav/useHorizontalNavMenuHeaderLink'
import { useUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import themeConfig from '@themeConfig'

export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, updateIsActive } = useHorizontalNavMenuHeaderLink(props.item)

    // ACL
    // const { canViewHorizontalNavMenuHeaderLink } = useAclUtils()
    const { canViewHorizontalNavMenuHeaderLink } = true

    // i18n
    const { t } = useUtils()

    return {
      isActive,
      updateIsActive,

      // alternate icons
      alternateIcon: themeConfig.menu.groupChildIcon,

      // i18n
      t,

      // ACL
      canViewHorizontalNavMenuHeaderLink,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.horizontal-nav-menu-header-link.header-link-active {
  box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
  @include elevationTransition();
}
</style>
