import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import faceit from './app/faceit'
import app from './app/index'
import player_draft from './app/player_draft'
import post from './app/posts'
import report from './app/reports'
import social from './app/social'
import statistics from './app/statistics'
import tournament from './app/tournaments'
import user from './app/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    statistics,
    faceit,
    user,
    post,
    tournament,
    report,
    social,
    player_draft,
  },
})
