import axiosClient from '@/plugins/axios'
import Vue from 'vue'

var url = undefined
export default {
  namespaced: true,
  state: {
    adminUnassignedReports: [],
    adminAssignedReports: [],
    reporteeReports: [],
    reporterReports: [],
  },
  mutations: {
    SetUnassignedReports: (state, { page, reports }) => {
      Vue.set(state.adminUnassignedReports, page, reports)
    },
    SetAssignedReports: (state, { page, reports }) => {
      Vue.set(state.adminAssignedReports, page, reports)
    },
    SetReporteeReports: (state, { page, reports }) => {
      Vue.set(state.reporteeReports, page, reports)
    },
    SetReporterReports: (state, { page, reports }) => {
      Vue.set(state.reporterReports, page, reports)
    },
  },
  actions: {
    async loadAdminUnassignedReports(state, { page }) {
      if (url === undefined) url = this._vm.$API
      let last = null
      if (page > 1) last = state.state.adminUnassignedReports[page - 2].last

      let params = new URLSearchParams()
      if (last) {
        params.append('report_id', last.report_id)
        params.append('reportee_id', last.reportee_id)
        params.append('timestamp', last.timestamp)
      }

      let response = await axiosClient.get(url + `/report/unassigned`, { params })
      state.commit('SetUnassignedReports', { page: page - 1, reports: response.data })
    },
    async reloadUnassignedReportsPages(state) {
      let maxPage = state.state.adminUnassignedReports.length
      for (let i = 1; i <= maxPage; i++) {
        await state.dispatch('report/loadAdminUnassignedReports', { page: i }, { root: true })
      }
    },
    async loadAdminAssignedReports(state, { page }) {
      if (url === undefined) url = this._vm.$API
      let last = null
      if (page > 1) last = state.state.adminAssignedReports[page - 2].last

      let params = new URLSearchParams()
      if (last) {
        params.append('report_id', last.report_id)
        params.append('reportee_id', last.reportee_id)
        params.append('timestamp', last.timestamp)
      }

      let response = await axiosClient.get(url + `/report/assigned`, { params })
      state.commit('SetAssignedReports', { page: page - 1, reports: response.data })
    },
    async reloadAssignedReportsPages(state) {
      let maxPage = state.state.adminAssignedReports.length
      for (let i = 1; i <= maxPage; i++) {
        await state.dispatch('report/loadAdminAssignedReports', { page: i }, { root: true })
      }
    },
    async loadReporteeReports(state, { page }) {
      if (url === undefined) url = this._vm.$API
      let last = null
      if (page > 1) last = state.state.reporteeReports[page - 2].last

      let params = new URLSearchParams()
      params.append('role', 'reportee')
      if (last) {
        params.append('report_id', last.report_id)
        params.append('reportee_id', last.reportee_id)
        params.append('timestamp', last.timestamp)
      }

      let response = await axiosClient.get(url + `/report`, { params })
      state.commit('SetReporteeReports', { page: page - 1, reports: response.data })
    },
    async reloadReporteeReportsPages(state) {
      let maxPage = state.state.reporteeReports.length
      for (let i = 1; i <= maxPage; i++) {
        await state.dispatch('report/loadReporteeReports', { page: i }, { root: true })
      }
    },
    async loadReporterReports(state, { page }) {
      if (url === undefined) url = this._vm.$API
      let last = null
      if (page > 1) last = state.state.reporterReports[page - 2].last

      let params = new URLSearchParams()
      params.append('role', 'reporter')
      if (last) {
        params.append('report_id', last.report_id)
        params.append('reporter_id', last.reporter_id)
        params.append('timestamp', last.timestamp)
      }

      let response = await axiosClient.get(url + `/report`, { params })
      state.commit('SetReporterReports', { page: page - 1, reports: response.data })
    },
    async reloadReporterReportsPages(state) {
      let maxPage = state.state.reporterReports.length
      for (let i = 1; i <= maxPage; i++) {
        await state.dispatch('report/loadReporterReports', { page: i }, { root: true })
      }
    },
  },
  getters: {
    HasUnassignedReportsPageLoaded: state => page => state.adminUnassignedReports.length >= page,
    GetUnassignedReports: state => page => state.adminUnassignedReports.at(page - 1),
    GetUnassignedReportCount: state => () => {
      let lastPost = state.adminUnassignedReports.find(xy => xy.last === undefined)
      if (lastPost === undefined) return 10000000
      return state.adminUnassignedReports.indexOf(lastPost) + 1
    },
    HasAssignedReportsPageLoaded: state => page => state.adminAssignedReports.length >= page,
    GetAssignedReports: state => page => state.adminAssignedReports.at(page - 1),
    GetAssignedReportCount: state => () => {
      let lastPost = state.adminAssignedReports.find(xy => xy.last === undefined)
      if (lastPost === undefined) return 10000000
      return state.adminAssignedReports.indexOf(lastPost) + 1
    },
    HasReporteeReportsPageLoaded: state => page => state.reporteeReports.length >= page,
    GetReporteeReports: state => page => state.reporteeReports.at(page - 1),
    GetReporteeReportCount: state => () => {
      let lastPost = state.reporteeReports.find(xy => xy.last === undefined)
      if (lastPost === undefined) return 10000000
      return state.reporteeReports.indexOf(lastPost) + 1
    },
    HasReporterReportsPageLoaded: state => page => state.reporterReports.length >= page,
    GetReporterReports: state => page => state.reporterReports.at(page - 1),
    GetReporterReportCount: state => () => {
      let lastPost = state.reporterReports.find(xy => xy.last === undefined)
      if (lastPost === undefined) return 10000000
      return state.reporterReports.indexOf(lastPost) + 1
    },
  },
}
