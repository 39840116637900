const admin = [
  {
    path: '/admin',
    name: 'admins',
    component: () => import('@/views/pages/Admin.vue'),
    children: [
      {
        path: '',
        name: 'admins-home',
        component: () => import('@/views/components/admin/Dashboard.vue'),
        meta: {
          layout: 'content',
          resource: 'view_admin_dashboard',
        },
      },
      {
        path: 'article',
        name: 'admins-article',
        component: () => import('@/views/components/admin/PostArea.vue'),
        meta: {
          layout: 'content',
          resource: 'manage_articles',
        },
      },
      {
        path: 'report',
        name: 'admins-report',
        component: () => import('@/views/components/admin/ReportArea.vue'),
        meta: {
          layout: 'content',
          resource: 'manage_reports',
        },
      },
      {
        path: 'social',
        name: 'admins-social',
        component: () => import('@/views/components/admin/SocialsArea.vue'),
        meta: {
          layout: 'content',
          resource: 'manage_socials',
        },
      },
    ],
    meta: {
      layout: 'content',
      resource: 'view_admin_dashboard',
    },
  },
]

export default admin
