const tournament = [
  {
    path: '/tournament',
    name: 'tournament',
    component: () => import('@/views/pages/Tournament.vue'),
    children: [
      {
        path: '',
        name: 'tournament-home',
        component: () => import('@/views/components/league/HomeTab.vue'),
        meta: {
          layout: 'content',
          resource: 'Public',
        },
      },
      {
        path: 'bans',
        name: 'league-bans',
        component: () => import('@/views/components/league/BanTab.vue'),
        meta: {
          layout: 'content',
          resource: 'Public',
        },
      },
    ],
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
]

export default tournament
