<template>
  <div>
    <v-menu
      v-if="$store.getters['user/IsLoggedIn']"
      ref="menu"
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar size="40">
            <v-img :src="userData.avatar"></v-img>
          </v-avatar>

          <span class="ml-2 font-weight-bold">
            {{ userData.nickname }}
          </span>
          <v-icon size="18">
            {{ icons.mdiChevronDown }}
          </v-icon>
        </div>
      </template>
      <v-list>
        <v-divider></v-divider>
        <v-list-item @click="$router.push('/profile')">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>My profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push('/profile/edit')">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCogOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Edit profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <hr
          role="separator"
          aria-orientation="horizontal"
          class="v-divider theme--dark"
        >
        <v-list-item @click="isReportCardVisible = true">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAlertOctagon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Make a report</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push('/reports')">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiClipboardList }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>My reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logoutUser">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      v-else
      block
      type="submit"
      class="faceit"
      @click="authenticate()"
    >
      Login with FaceIt
    </v-btn>
    <MakeReportCard v-model="isReportCardVisible" />
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiAlertOctagon,
  mdiClipboardList,
  mdiChevronDown,
} from '@mdi/js'

import { getCurrentInstance } from '@vue/composition-api'
import MakeReportCard from '@/views/components/report/MakeReportCard.vue'

export default {
  components: {
    MakeReportCard,
  },
  data() {
    return {
      isReportCardVisible: false,
    }
  },
  computed: {
    userData() {
      return this.$store.getters['user/GetLocalUserData']
    },
  },
  methods: {
    authenticate() {
      const state = Math.floor(Math.random() * (999999999 - 0 + 1)) + 0
      localStorage.setItem('state', state)
      this.$auth.oauth2('faceit', {
        params: {
          state,
          redirect_popup: true,
        },
      })
    },
    logoutUser() {
      localStorage.removeItem('accessToken')
      this.$store.dispatch('user/setUserData', {})
      this.$router.push('/')
    },
  },
  setup() {
    const vm = getCurrentInstance().proxy

    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiAlertOctagon,
        mdiClipboardList,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
