import axiosClient from '@/plugins/axios'
import Vue from 'vue'

var url = undefined
var intervalCreated = false
export default {
  namespaced: true,
  state: {
    drafted: [],
  },
  mutations: {
    ResetDraft: state => {
      state.drafted = []
    },
    SetDraftedPlayers: (state, { page, players }) => {
      for (var player of players.data) {
        if (!player.statistics) player.statistics = {}
      }
      Vue.set(state.drafted, page, players)
    },
  },
  actions: {
    async loadPlayers(state, { page }) {
      if (url === undefined) url = this._vm.$API
      if (!intervalCreated) {
        intervalCreated = true
        setInterval(() => {
          state.dispatch('reloadPages')
        }, 1000 * 60 * 15)
      }

      let last = null
      if (page > 1) last = state.state.drafted[page - 2].last
      let params = new URLSearchParams()
      if (last) {
        params.append('faceit_id', last.faceit_id)
        params.append('draft_create_timestamp', last.draft_create_timestamp)
      }
      let response = await axiosClient.get(url + `/draft/players/available/`, { params })
      state.commit('SetDraftedPlayers', { page: page - 1, players: response.data })
    },
    async reloadPages(state) {
      let maxPage = state.state.drafted.length
      for (let i = 1; i <= maxPage; i++) {
        await state.dispatch('player_draft/loadPlayers', { page: i }, { root: true })
      }
    },
  },
  getters: {
    HasPageLoaded: state => page => state.drafted.length >= page,
    GetAllPages: state => state.drafted,
    GetDraftedPlayers: state => page => state.drafted.at(page - 1),
    GetCount: state => () => {
      let lastDraft = state.drafted.find(xy => xy.last === undefined)
      if (lastDraft === undefined) return 10000000
      return state.drafted.indexOf(lastDraft) + 1
    },
  },
}
