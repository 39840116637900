import axiosClient from '@/plugins/axios'
import Vue from 'vue'

var url = undefined
export default {
  namespaced: true,
  state: {
    posts: [],
  },
  mutations: {
    ResetPosts: state => {
      state.posts = []
    },
    SetPosts: (state, { page, posts }) => {
      Vue.set(state.posts, page, posts)
    },
  },
  actions: {
    async loadPosts(state, { page }) {
      if (url === undefined) url = this._vm.$API
      let last = null
      if (page > 1) last = state.state.posts[page - 2].last
      let params = new URLSearchParams()
      if (last) {
        params.append('id', last.id)
        params.append('timestamp', last.timestamp)
      }
      let response = await axiosClient.get(url + `/article/`, { params })
      state.commit('SetPosts', { page: page - 1, posts: response.data })
    },
    async reloadPages(state) {
      let maxPage = state.state.posts.length
      for (let i = 1; i <= maxPage; i++) {
        state.dispatch('post/loadPosts', { page: i }, { root: true })
      }
    },
  },
  getters: {
    HasPageLoaded: state => page => state.posts.length >= page,
    GetAllPages: state => state.posts,
    GetPosts: state => page => state.posts.at(page - 1),
    GetCount: state => () => {
      let lastPost = state.posts.find(xy => xy.last === undefined)
      if (lastPost === undefined) return 10000000
      return state.posts.indexOf(lastPost) + 1
    },
  },
}
