import axiosClient from '@/plugins/axios'
import Vue from 'vue'

let url
export default {
  namespaced: true,
  state: {
    streams: null,
    public_streams: null,
    twitter: null,
    public_twitter: null,
  },
  mutations: {
    SetStreams: async (state, streams) => {
      Vue.set(state, 'streams', streams)
    },
    SetPublicStreams: async (state, streams) => {
      for (let stream of streams) {
        if (stream.game_name == 'Counter-Strike: Global Offensive') stream.game_name = 'CS:GO'
        if (stream.game_name == 'Old School RuneScape') stream.game_name = 'OSRS'
        if (stream.game_name.startsWith('PUBG')) stream.game_name = 'PUBG'
      }
      Vue.set(state, 'public_streams', streams)
    },
    SetTwitter: async (state, streams) => {
      Vue.set(state, 'twitter', streams)
    },
    SetPublicTwitter: async (state, streams) => {
      Vue.set(state, 'public_twitter', streams)
    },
  },
  actions: {
    async loadStreams(state) {
      if (url === undefined) {
        url = this._vm.$API
      }
      const response = await axiosClient.get(`${url}/social?type=twitch`)
      state.commit('SetStreams', response.data)
    },
    async loadLiveStreams(state) {
      if (state.state.public_streams !== null) return
      if (url === undefined) {
        url = this._vm.$API
      }

      const response = await axiosClient.get(`${url}/social/public?type=twitch`)
      state.commit('SetPublicStreams', response.data)
    },
    async updateStreams(state, data) {
      state.commit('SetStreams', data)
    },
    async loadTwitter(state) {
      if (url === undefined) {
        url = this._vm.$API
      }

      const response = await axiosClient.get(`${url}/social?type=twitter`)
      state.commit('SetTwitter', response.data)
    },
    async loadPublicTwitter(state) {
      if (state.state.public_twitter !== null) return
      if (url === undefined) {
        url = this._vm.$API
      }

      const response = await axiosClient.get(`${url}/social/public?type=twitter`)
      state.commit('SetPublicTwitter', response.data)
    },
    async updateTwitter(state, data) {
      state.commit('SetTwitter', data)
    },
  },
  getters: {
    GetStreams: state => state.streams,
    GetPublicStreams: state => state.public_streams,
    GetTwitter: state => state.twitter,
    GetPublicTwitter: state => state.public_twitter,
  },
}
