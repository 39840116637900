const util = [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/error-authorize',
    name: 'error-authorize',
    component: () => import('@/views/ErrorAuthorize.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/Auth.vue'),
    meta: {
      redirectIfLoggedIn: true,
      verifyState: true,
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/auth/discord',
    name: 'auth-discord',
    component: () => import('@/views/Auth.vue'),
    meta: {
      verifyState: true,
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/auth/twitch',
    name: 'auth-twitch',
    component: () => import('@/views/Auth.vue'),
    meta: {
      verifyState: true,
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/auth/twitter',
    name: 'auth-twitter',
    component: () => import('@/views/Auth.vue'),
    meta: {
      verifyState: true,
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

export default util
