import axiosClient from '@/plugins/axios'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'
import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'
import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import LazyYoutube from 'vue-lazytube'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.component('LazyYoutube', LazyYoutube)
Vue.config.productionTip = false

Vue.use(auth, {
  plugins: {
    http: axiosClient,
    router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
    oauth2: {
      faceit: {
        url: 'https://accounts.faceit.com/',

        params: {
          client_id: '808d6a07-a296-41df-b3c8-f07c7e6c2e4a',
          redirect_uri: 'auth',
          response_type: 'code',
          state: {},
        },
      },
      discord: {
        url: 'https://discord.com/api/oauth2/authorize',

        params: {
          client_id: '942266905991987261',
          redirect_uri: 'https://balticleague.gg/auth/discord',
          response_type: 'code',
          scope: 'identify',
          state: {},
        },
      },
      twitch: {
        url: 'https://id.twitch.tv/oauth2/authorize',

        params: {
          client_id: 'wjdskpr3srxaao57dotdtbjj23v7fn',
          redirect_uri: 'https://balticleague.gg/auth/twitch',
          response_type: 'code',
          scope: '',
          state: {},
        },
      },
      twitter: {
        url: 'https://twitter.com/i/oauth2/authorize',

        params: {
          client_id: 'QmZ3WDNRTG5qSzlXX28wT2Z0VzM6MTpjaQ',
          redirect_uri: 'https://balticleague.gg/auth/twitter',
          response_type: 'code',
          scope: 'tweet.read users.read',
          code_challenge_method: 'plain',
          state: {},
        },
      },
    },
  },
})

Vue.use(VueAxios, axios)

// Vue.prototype.$API = 'http://127.0.0.1:3000'
Vue.prototype.$API = 'https://cnoeb7534h.execute-api.eu-north-1.amazonaws.com/v1'

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
