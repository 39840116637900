import axiosClient from '@/plugins/axios'
import Vue from 'vue'

var url = undefined
const headers = { Accept: 'application/json' }

export default {
  namespaced: true,
  state: {
    localUser: { data: {}, recentMatches: undefined, loaded: false },
    users: {},
  },
  mutations: {
    SetLocalUserData: (state, data) => {
      if (Object.keys(data).length > 0 && !data.statistics) data.statistics = {}
      Vue.set(state.localUser, 'data', data)
      Vue.set(state.localUser, 'loaded', true)
    },
    setSocialintegrationId: (state, { type, id }) => {
      Vue.set(state.localUser.data, type + '_id', id)
    },
    setSocialintegrationUsername: (state, { type, username }) => {
      Vue.set(state.localUser.data, type + '_username', username)
    },
    SetUserData: (state, data) => {
      if (!data.statistics) data.statistics = {}
      if (state.users[data.faceit_id] === undefined)
        Vue.set(state.users, data.faceit_id, { data: {}, recentMatches: undefined })
      state.users[data.faceit_id].data = data.data
    },
    SetUserRecentMatches: (state, data) => {
      if (state.localUser.data.faceit_id == data.id) state.localUser.recentMatches = data.recentMatches
      else state.users[data.id].recentMatches = data.recentMatches
    },
  },
  actions: {
    async loadLocalUserData(state) {
      if (url === undefined) url = this._vm.$API

      axiosClient
        .get(url + `/auth/me`, {})
        .then(response => {
          state.commit('SetLocalUserData', response.data)
          if (Object.keys(response.data).length <= 0) localStorage.removeItem('accessToken')
        })
        .catch(e => {
          console.log(e)
        })
    },
    async loadUserData(state, id) {
      if (url === undefined) url = this._vm.$API
      if (localStorage.getItem('accessToken') === undefined) return
      if (state.state.users[id]?.data !== undefined) {
        return
      }

      axiosClient
        .get(url + `/profile/${id}/`, {}, { headers })
        .then(response => {
          state.commit('SetUserData', { faceit_id: response.data.faceit_id, data: response.data })
        })
        .catch(e => {
          state.commit('SetUserData', { faceit_id: id, data: null })
        })
    },
    async loadPlayerRecentMatches(state, id) {
      if (url === undefined) url = this._vm.$API

      if (localStorage.getItem('accessToken') === undefined) return
      if (
        state.state.users[id]?.recentMatches !== undefined ||
        (state.state.localUser.data.faceit_id == id && state.state.localUser.recentMatches !== undefined)
      ) {
        return
      }

      axiosClient
        .get(url + `/profile/${id}/matches/recent`, {}, { headers })
        .then(response => {
          state.commit('SetUserRecentMatches', { id: id, recentMatches: response.data })
        })
        .catch(e => {
          state.commit('SetUserData', { faceit_id: id, data: null })
        })
    },
    setUserData(state, data) {
      state.commit('SetLocalUserData', data)
    },
    setSocialintegrationId(state, data) {
      state.commit('setSocialintegrationId', data)
    },
    setSocialintegrationUsername(state, data) {
      state.commit('setSocialintegrationUsername', data)
    },
  },
  getters: {
    IsLocalUserLoaded: state => state.localUser.loaded,
    IsLoggedIn: state =>
      Object.keys(state.localUser.data).length > 0 && localStorage.getItem('accessToken') !== undefined,
    IsAuthorized: state => resource =>
      resource === undefined ||
      (Object.keys(state.localUser.data).length > 0 &&
        localStorage.getItem('accessToken') !== undefined &&
        state.localUser.data.permissions !== undefined &&
        state.localUser.data.permissions.includes(resource)),
    GetLocalUserId: state => state.localUser.data.faceit_id,
    GetLocalUserData: state => state.localUser.data,
    GetLocalUserRecentMatches: state => state.localUser.recentMatches,
    GetUserCount: state => Object.keys(state.users).length,
    GetUserData: state => id => state.users[id]?.data,
    GetUserRecentMatches: state => id => state.users[id]?.RecentMatches,
  },
}
