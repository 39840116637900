import { mdiAlertOctagon, mdiDiscord, mdiHeadset, mdiHomeOutline, mdiTrophyVariant } from '@mdi/js'

export default [
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'home',
  },
  {
    title: 'Admin',
    icon: mdiHomeOutline,
    to: 'admin',
    resource: 'view_admin_dashboard',
  },
  {
    title: 'Draft',
    icon: mdiAlertOctagon,
    to: 'draft',
  },
  // {
  //   title: 'Play',
  //   icon: mdiTrophy,
  //   to: 'tournament',
  // },
  {
    title: 'About Us',
    icon: mdiTrophyVariant,
    to: 'about',
  },
  {
    title: 'Discord',
    icon: mdiDiscord,
    to: 'https://discord.gg/ySaHG4gGuh',
    outsideLink: true,
  },
  {
    title: 'Teamspeak',
    icon: mdiHeadset,
    to: 'ts3server://ts.wortex.ee?port=9987',
    outsideLink: true,
  },
]
