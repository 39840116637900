import Vue from 'vue'
import VueRouter from 'vue-router'
import admin from './admin'
import league from './league'
import profile from './profile'
import tournament from './tournament'
import util from './util'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/pages/Home.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/pages/Blog.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },

  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/pages/Privacy.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('@/views/pages/Shop.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/articles/:id',
    name: 'article-open',
    component: () => import('@/views/pages/PostContent.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/tournaments/:id',
    name: 'tournament-open',
    component: () => import('@/views/pages/Tournament.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/draft',
    name: 'draft',
    component: () => import('@/views/pages/Draft.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/pages/About.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/reports',
    name: 'my-reports',
    component: () => import('@/views/pages/MyReports.vue'),
    meta: {
      layout: 'content',
      resource: 'AuthorizedNoPermission',
    },
  },
  ...admin,
  ...league,
  ...profile,
  ...tournament,
  ...util,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach(async (to, _, next) => {
  if (!router.app.$store.getters['user/IsLocalUserLoaded']) {
    await new Promise(resolve => {
      var interval = setInterval(() => {
        if (router.app.$store.getters['user/IsLocalUserLoaded']) {
          clearInterval(interval)
          resolve()
        }
      }, 10)
    })
  }

  if (to.meta.verifyState) {
    const url = new URL(location.href)
    const urlState = url.searchParams.get('state')
    const storageState = localStorage.getItem('state')
    if (storageState === undefined || urlState === undefined || storageState !== urlState) return next('/')
  }

  const isLoggedIn = router.app.$store.getters['user/IsLoggedIn']
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next('/')
  }

  if (to.meta.resource === 'AuthorizedNoPermission') {
    if (!isLoggedIn) return next('/error-authorize')
  } else if (to.meta.resource !== 'Public' && !router.app.$store.getters['user/IsAuthorized'](to.meta.resource)) {
    return next('/error-authorize')
  }

  return next()
})

export default router
