import axiosClient from '@/plugins/axios'

var url = undefined
var intervalCreated = false
export default {
  namespaced: true,
  state: {
    draftedPlayerCount: 0,
  },
  mutations: {
    SetDraftedPlayerCount: (state, count) => {
      state.draftedPlayerCount = count
    },
  },
  actions: {
    async loadPlayerDraftCount(state) {
      if (url === undefined) url = this._vm.$API
      if (!intervalCreated) {
        intervalCreated = true
        setInterval(() => {
          state.dispatch('loadPlayerDraftCount')
        }, 1000 * 60 * 10)
      }
      let response = await axiosClient.get(url + `/statistic/player_draft_count`)
      state.commit('SetDraftedPlayerCount', response.data.player_count)
    },
  },
  getters: {
    GetDraftedPlayerCount: state => state.draftedPlayerCount,
  },
}
