import {
  mdiAccountGroup,
  mdiDiscord,
  mdiHeadset,
  mdiHomeOutline,
  mdiInformation,
  mdiNewspaperVariantMultipleOutline,
  mdiShieldAccount,
} from '@mdi/js'

export default [
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'home',
  },
  {
    title: 'Blog',
    icon: mdiNewspaperVariantMultipleOutline,
    to: 'blog',
  },

  // {
  //   title: 'Shop Catalgoue',
  //   icon: mdiNewspaperVariantMultipleOutline,
  //   to: 'shop',
  // },
  {
    title: 'Admin',
    icon: mdiShieldAccount,
    to: 'admins-home',
    resource: 'view_admin_dashboard',
  },

  // {
  //   title: 'Play',
  //   icon: mdiTrophy,
  //   to: 'tournament',
  // },
  {
    title: 'About us',
    icon: mdiInformation,
    to: 'about',
  },
  {
    title: 'Draft',
    icon: mdiAccountGroup,
    to: 'draft',
  },
  {
    title: 'Discord',
    icon: mdiDiscord,
    to: 'https://discord.gg/hhdfgKRCkK',
    outsideLink: true,
  },
  {
    title: 'Teamspeak3',
    icon: mdiHeadset,
    to: 'ts3server://ts.wortex.ee?port=9987',
    outsideLink: true,
  },
]
